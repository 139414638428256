#pgCursoSite {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  #pgCursoSite {
    flex-direction: column;
  }

  #pgCursoSite .price {
    width: 100%;
  }

  #pgCursoSite .info {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  #pgCursoSite {
    flex-direction: row;
  }

  #pgCursoSite .price {
    width: 28%;
  }

  #pgCursoSite .info {
    width: 70%;
  }
}

#pgCursoSite .painel {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 15px 0px #dbdbdb;
  box-shadow: 0px 0px 15px 0px #dbdbdb;
}

#pgCursoSite .painel .imgsCarousel .carousel-slider {
  /* min-height: 250px; */
  max-height: calc(100vh * .8);
}

#pgCursoSite .painel .imgsCarousel {
  margin-top: 15px;
}

#pgCursoSite .painel .imgsCarousel img {
  max-height: calc(100vh * .8);
  object-fit: scale-down;
}


#pgCursoSite .painel .capa {
  min-height: 200px;
  max-height: calc(100vh * .8);
  margin-top: 15px;
  object-fit: scale-down;
  background-color: #e3e3e3;
}

#pgCursoSite .info {
  white-space: pre-line;
  line-height: 27px;
}

#pgCursoSite .info h1 {
  line-height: 40px;
}

#pgCursoSite .info p {
  margin-top: 15px;
}

#pgCursoSite .price button {
  border: none;
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  font-size: 18px;
  background-color: #6dbc25;
  color: #fff;
  padding: 8px 25px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

#pgCursoSite .price {
  height: 225px;
}

#pgCursoSite .price h2 {
  margin-bottom: 10px;
}

#pgCursoSite .price p {
  font-size: 14px;
  margin-bottom: 15px;
}

#pgCursoSite .price p b {
  font-size: 16px;
  font-weight: 500;
}