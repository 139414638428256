* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f5f6f9;
  color: #616e79;
}

body,
input,
button,
textarea {
  font: 400 16px "Roboto", sans-serif;
  outline: none;
}

button {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  background-clip: padding-box;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::placeholder {
  color: #9d9fc5;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #9d9fc5;
}

::-ms-input-placeholder {
  color: #9d9fc5;
}

button:hover {
  opacity: 0.75;
}

select {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  padding: 4px 16px;
  font-size: 14px;
  background: #e8ebf3;
  color: #333;
  border: none;
}

.btn-green {
  min-height: 38px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #00e0aa !important;
}

.btn-blue {
  min-height: 38px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #00bcf1 !important;
}

.btn-purple {
  min-height: 38px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #6f66ff !important;
}

.btn-red {
  min-height: 38px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #ff5349 !important;
}

.btnSmall {
  min-height: 27px !important;
}

.painel {
  width: 100%;
  border-radius: 5px;
  background-color: #FFF;
  margin: 15px 0px;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 15px 0px #eee;
  box-shadow: 0px 0px 15px 0px #eee;
}

.modal {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal .body {
  height: calc(100vh - 110px);
  overflow: auto;
}

.modal .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #477ff4;
  color: #FFF;
  font-size: 22px;
  font-weight: 550;
}

.modal .headTable {
  margin: 15px 15px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #477ff4;
  padding: 10px 10px;
  color: #FFF;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

.modal .contentTable {
  margin: 0px 15px 15px 15px;
  border: 1px solid #ddd;
  min-height: 300px;
  border-radius: 0px 0px 5px 5px;
}

.modal .rowTable {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  color: #888;
  font-size: 14px;
  align-items: center;
}

.modal .rowTable:hover {
  background: #eee;
}

.modal .rowTable span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.modal .rowTable span button {
  font-size: 14px;
  height: 30px;
  width: 30px;
  border: 0px;
  border-radius: 5px;
  color: #FFF;
  cursor: pointer;
}

.modal .body .divFormModal {
  display: flex;
  flex-direction: row;
  margin: 10px 15px;
  gap: 10px;
}

.modal .body .divFormModal button {
  font-size: 20px;
  padding: 0px 15px;
  height: 45px;
}

.modal .body .btnNovo {
  font-size: 20px;
  padding: 5px 10px;
}

.modal .body .info {
  margin: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal .body .info h4 {
  font-weight: 500;
}