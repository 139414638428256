#dvDatePicker .customInputDate {
  height: 45px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 4px 16px;
  font-size: 14px;
  background: #e8ebf3;
  color: #333;
}

#dvDatePicker .react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none;
}

#dvDatePicker .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
  border-radius: 7px;
}

#dvDatePicker .react-calendar__tile--active {
  background: #006edc;
  color: white;
  border-radius: 7px;
}

#dvDatePicker .react-calendar__tile--now {
  background: #dce1e7;
  color: #333;
  border-radius: 7px;
}